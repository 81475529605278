<template>
  <div class="my-activity">
    <van-sticky>
      <div class="query">
        <input type="text" placeholder="请输入团队名称或活动标题查询" v-model="name">
        <button @click="selectActivity">搜索</button>
        <img src="~img/25.png">
      </div>
    </van-sticky>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <ul>
        <li v-for="item in activityList" :key="item.id">
          <div class="top-box" @click="toActivity(item)">
            <div class="left">
              <img :src="item.activity.image">
            </div>
            <div class="right">
              <p class="p1 one-txt-cut">{{item.activity.name}}</p>
              <p class="p2 one-txt-cut">{{item.activity.team.name}}</p>
              <div>
                <img src="~img/22.png">
                <p class="one-txt-cut">活动地点：{{item.activity.address}}</p>
              </div>
              <div>
                <img src="~img/23.png">
                <p class="one-txt-cut">报名时间：{{item.activity.enroll_start_time}}-{{item.activity.enroll_end_time}}
                </p>
              </div>
            </div>
          </div>
          <div class="bom-box">
            <p>提交时间：{{item.created_at}}</p>
            <div class="btn">
              <p>
                状态：
                <span v-if="item.status === 1">未签到</span>
                <span v-else-if="item.status === 2">已签到</span>
                <span v-else-if="item.status === 3">违约未参加</span>
                <span v-else-if="item.status === 4">已驳回</span>
                <span v-if="item.status === 4" class="reject">理由: {{item.msg}}</span>
              </p>
              <div class="btn-box" v-if="item.status === 1">
                <button @click="getQrcode(item)">签到二维码</button>
                <button @click="cancelRegistration(item.activity.id)">取消报名</button>
                <!-- <div class="qrcode" v-show="showQRcode">
                  <div class="qrcode-img" v-html="item.qrcode"></div>
                  <p>现场出示签到二维码</p>
                </div> -->
              </div>
            </div>
          </div>
        </li>
      </ul>
    </van-list>
    <van-overlay :show="showLoading">
      <van-loading color="#0094ff">加载中...</van-loading>
    </van-overlay>
    <van-overlay :show="showQRcode" @click="showQRcode = false">
      <div class="qrcode-img">
        <div v-html="qrcode"></div>
        <p>现场出示签到二维码</p>
      </div>
    </van-overlay>
    <tabbar />
  </div>
</template>

<script>
import tabbar from "../../components/Tabbar.vue";

export default {
  components: {
    tabbar,
  },
  data() {
    return {
      showQRcode: false,
      loading: false,
      finished: false,
      limit: 5,
      page: 0,
      name: "",
      activityList: [],
      showLoading: false,
      qrcode: "",
    };
  },
  created() { },
  methods: {
    changeQRcode() {
      this.showQRcode = true;
    },
    onLoad() {
      this.page++;
      this.getmyActivity();
    },
    async getmyActivity() {
      this.showLoading = true;
      const res = await this.axios.get(
        `user/activity?limit=${this.limit}&page=${this.page}&name=${this.name}`
      );
      if (res.code === 200) {
        const { list, meta } = res.data;
        this.showLoading = false;
        list.forEach((item) => {
          item.activity.enroll_end_time =
            item.activity.enroll_end_time.split(" ")[0];
          item.activity.enroll_start_time =
            item.activity.enroll_start_time.split(" ")[0];
          this.activityList.push(item);
        });
        console.log(this.activityList)

        this.loading = false;
        if (this.activityList.length >= meta.total) {
          this.finished = true;
        }
      }
    },
    async getQrcode(item) {
      this.showLoading = true;
      const res = await this.axios.get(`qrcode/${item.activity_id}`);
      this.qrcode = res;
      this.showLoading = false;
      this.showQRcode = true;
      // console.log(res);
      // console.log(item.qrcode);
    },
    selectActivity() {
      this.page = 1;
      this.activityList = [];
      this.finished = false;
      this.getmyActivity();
    },
    async cancelRegistration(id) {
      const res = await this.axios.delete(`user/activity/${id}`);
      if (res.code === 200) {
        this.$toast("取消成功");
        this.page = 1;
        this.activityList = [];
        this.finished = false;
        this.getmyActivity();
      }
    },
    toActivity(item) {
      console.log(item);
      this.$router.push({ path: `/activityDetails?id=${item.activity_id}` });
    },
  },
};
</script>


<style lang="less" scoped>
.my-activity {
  li {
    font-size: 0;
  }
  .query {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-top: 31px;
    background: #f8f8f8;
    img {
      width: 31px;
      left: 55px;
      top: 65%;
      transform: translate(0, -50%);
      position: absolute;
    }
    button {
      font-size: 27px;
      color: #fff;
      width: 89px;
      height: 72px;
      border-radius: 10px;
      background: url("../../assets/img/24.png") no-repeat;
      background-size: contain;
      margin-right: 30px;
    }
    input {
      width: 586px;
      height: 72px;
      font-size: 27px;
      background: #fff;
      border-radius: 10px;
      padding-left: 80px;
      margin-left: 30px;
    }
    ::-webkit-input-placeholder {
      /* WebKit browsers，webkit内核浏览器 */
      color: #a4a4a4;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #a4a4a4;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #a4a4a4;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #a4a4a4;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    }
  }
  ul {
    padding-top: 30px;
    li {
      width: 92%;
      margin: auto;
      background: #f0f0f0;
      border-radius: 18px;
      margin-bottom: 26px;
      .top-box {
        height: 264px;
        background: #ffffff;
        border-radius: 18px;
        display: flex;
        align-items: center;
        padding-left: 31px;
        .left {
          display: flex;
          align-items: center;
          img {
            width: 196px;
            height: 196px;
            border-radius: 10px;
          }
        }
        .right {
          font-family: FZLTHJW--GB1-0, FZLTHJW--GB1;
          padding-left: 20px;
          .p1 {
            font-size: 28px;
            width: 417px;
          }
          .p2 {
            font-size: 24px;
            color: #6f6f6f;
            margin-bottom: 45px;
            margin-top: 5px;
          }
          div {
            display: flex;
            align-items: center;
            margin-top: 10px;
            img {
              width: 21px;
              margin-right: 16px;
            }
            p {
              font-size: 24px;
              color: #6f6f6f;
              // margin-top: 10px;
            }
          }
        }
      }
      .bom-box {
        height: 129px;
        padding: 15px 30px 0px 30px;
        .reject {
          margin-left: 60px;
          color: #f26a52;
        }
        p {
          font-size: 24px;
          color: #6f6f6f;
        }
        .btn {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-top: 10px;
          .btn-box {
            position: relative;
            .qrcode {
              width: 320px;
              height: 350px;
              position: absolute;
              background: url("../../assets/img/45.png") no-repeat;
              background-size: 100% 100%;
              padding-top: 50px;
              left: -160px;
              bottom: -350px;
              text-align: center;
              overflow: hidden;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              img {
                width: 335px;
                margin: auto;
              }
              p {
                font-size: 24px;
                color: #6f6f6f;
                margin-top: 20px;
              }
            }
          }
          button {
            font-size: 24px;
            height: 44px;
            padding: 0 16px;
            background: #f26a52;
            border-radius: 5px;
            color: #fff;
            margin-left: 15px;
          }
        }
      }
    }
  }
  .van-overlay {
    z-index: 999;
  }
  .van-loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .qrcode-img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    p {
      font-size: 22px;
    }
  }
}
</style>